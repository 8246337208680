"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import Slider from "@unlikelystudio/react-slider"

import type { InferReturn } from "~/@types/generics"
import type { serializeCollectionNavigationItems } from "~/data/collection-navigation-items/serializer"
import { Link } from "~/components/ui/Link"
import { Stack } from "~/components/abstracts/Stack"

import { hideFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

export type CollectionNavigationProps = ComponentProps<"nav"> & {
  navigation: InferReturn<typeof serializeCollectionNavigationItems>
  customSliderRef?: React.RefObject<HTMLDivElement>
  navigationMobileClassName?: string
  withSlider?: boolean
}

export function CollectionNavigation({
  navigationMobileClassName,
  navigation,
  customSliderRef,
  withSlider = true,
  ...props
}: CollectionNavigationProps) {
  return (
    <nav data-comp="UI/Collection/CollectionDefault/CollectionNavigation" {...props}>
      <div className={clsx(css.HeaderNavigation)}>
        {navigation.map((link, id) => (
          <Link className={clsx(css.HeaderNavigationItem, css.WithBorder)} key={id} {...link} />
        ))}
      </div>
      {withSlider ? (
        <Slider
          dada-comp="CollectionNavigationSlider"
          className={hideFrom("l", "block")}
          containerClassName={clsx(css.HeaderNavigationMobile, navigationMobileClassName)}
          customSliderRef={customSliderRef}
        >
          {navigation.map((link, id) => (
            <Link className={clsx(css.HeaderNavigationItem, css.WithBorder)} key={id} {...link} />
          ))}
        </Slider>
      ) : (
        <Stack
          dada-comp="CollectionNavigationBlock"
          gap={6}
          sprinklesCss={{ flexWrap: "wrap", justifyContent: "center" }}
          hideFrom={"l"}
        >
          {navigation.map((link, id) => (
            <Link className={clsx(css.HeaderNavigationItem, css.WithBorder)} key={id} {...link} />
          ))}
        </Stack>
      )}
    </nav>
  )
}
